// Primary
$color-brand-primary: #1D71B8;

// Neutral
$color-neutral-light: #F2F2F2;
$color-neutral-medium: #CCCCCC;
$color-neutral-dark: #666666;
$color-neutral-darkest: #171717;
$color-neutral-background: #FFFFFF;

// Feedback
$color-feedback-success: #2FAB67;
$color-feedback-error: #EB5757;
$color-feedback-info: #3498db;
$color-feedback-warning: #f1c40f;
