@import 'styles/abstract/_colors.scss';

.container {
  height: 100%;
  padding: 10px 20px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notificationIconButtonContainer {
  background: transparent;
  border: none;
  position: relative;

  .hasNotification {
    position: absolute;
    top: 0;
    right: 5px;

    width: 0.8em;
    height: 0.8em;
    background: $color-feedback-error;
    border-radius: 50%;
  }

  &:hover {
    cursor: pointer;
  }
}
