.container {
  display: flex;
  flex-direction: column;
}

.storybookExamplePage {
  height: 800px;
  width: 100%;
  background: gray;
}

.mainContainer {
  padding-bottom: 60px;
}
